import { Link } from 'react-router-dom'

import { useApp } from 'Hooks'

export const AppLogo = ({ className, url, invert }) => {
  const { theme, product } = useApp()
  url = url || '/'

  const logoDarkImg = invert ? '/img/logo/light.png' : `/img/logo/${product}.png`
  const logoLightImg = invert ? `/img/logo/${product}.png` : '/img/logo/light.png'

  return (
    <Link className={className} to={url}>
      <img className={`object-contain ${theme === 'dark' ? 'hidden' : 'block'}`} src={logoDarkImg} alt="logo" />
      <img className={`object-contain ${theme === 'dark' ? 'block' : 'hidden'}`} src={logoLightImg} alt="logo" />
    </Link>
  )
}
