import React, { useEffect } from 'react'
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  ListContainer,
  Time,
  CreatedActivity,
  AccountLinkedActivity,
  UpdatedActivity,
  AccountLoginActivity,
  getActivityIcon,
  DeletedActivity,
  AccountPermissionsUpdatedActivity,
  AccountUnlinkedActivity,
  Avatar,
  AvatarFallback,
  AvatarImage
} from 'Components'

import API from 'API'
import { Actions } from 'Store'
import { useListItem } from 'Hooks'
import { getInitialsByName } from 'Utils'

const getActivityComponent = (type, data) => {
  switch (type) {
    case 'CREATED':
      return <CreatedActivity data={data} />
    case 'UPDATED':
      return <UpdatedActivity data={data} />
    case 'DELETED':
      return <DeletedActivity data={data} />
    case 'ACCOUNT_LOGIN':
      return <AccountLoginActivity data={data} />
    case 'ACCOUNT_LINKED':
      return <AccountLinkedActivity data={data} />
    case 'ACCOUNT_PERMISSIONS_UPDATED':
      return <AccountPermissionsUpdatedActivity data={data} />
    case 'ACCOUNT_UNLINKED':
      return <AccountUnlinkedActivity data={data} />
    default:
      return null
  }
}

const ActivityLogItem = ({ id }) => {
  const activityLog = useListItem('userActivityLogs', id)
  if (!activityLog) return null
  return (
    <li className="mb-10 ms-6">
      <span className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white dark:bg-blue-900 dark:ring-gray-900">
        {getActivityIcon(activityLog.type)}
      </span>
      <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-600 dark:bg-gray-700">
        <div className="items-center justify-between sm:flex">
          <div className="flex-1">{getActivityComponent(activityLog.type, activityLog.data)}</div>
          <div className="flex items-center gap-2 sm:ms-4">
            {activityLog.caller && (
              <div className="flex items-center gap-2">
                <Avatar className="h-6 w-6">
                  <AvatarImage src={activityLog.caller.avatarUrl} alt={activityLog.caller.name} />
                  <AvatarFallback className="text-xs">{getInitialsByName(activityLog.caller.name)}</AvatarFallback>
                </Avatar>
                <span className="text-sm text-gray-600 dark:text-gray-300">{activityLog.caller.name}</span>
              </div>
            )}
            <time className="text-xs font-normal text-gray-400 sm:order-last sm:mb-0">
              {<Time d={activityLog.ts} format="DD/MM/YYYY HH:mm" />}
            </time>
          </div>
        </div>
      </div>
    </li>
  )
}

export const UserActivityLogs = () => {
  useEffect(() => {
    const syncList = async () => {
      const userActivityLogs = await API._getUserActivityLogs()
      if (userActivityLogs?.error) return
      Actions.populateUserActivityLogs(userActivityLogs)
    }
    syncList()
  }, [])

  return (
    <Card className="w-full max-w-4xl">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Jurnal utilizator</CardTitle>
      </CardHeader>
      <CardContent className="max-h-[600px] overflow-y-auto">
        <ol className="relative border-s border-gray-200 dark:border-gray-700">
          <ListContainer list="userActivityLogs" id="id" sortKey="ts" isDesc>
            <ActivityLogItem />
          </ListContainer>
        </ol>
      </CardContent>
    </Card>
  )
}
