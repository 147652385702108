import { useSelector } from 'react-redux'
import { Button, Time } from 'Components'
import { FiMoreVertical, FiMapPin, FiEye } from 'react-icons/fi'
import { Actions } from 'Store'
import { Link } from 'react-router-dom'

export const AppCompanies = () => {
  const companies = useSelector((state) => state.auth.account?.userCompanies)
  const companyUserId = useSelector((state) => state.auth.account?.company?.companyUserId)

  return (
    <div className="py-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold">Firmele mele</h3>
        <Link to="/modals/company">
          <Button variant="outline" size="sm" className="h-8 hover:bg-primary/10 hover:text-primary transition-colors">
            Adaugă o altă firmă
          </Button>
        </Link>
      </div>

      <div className="space-y-2">
        {companies?.map((company) => (
          <div key={company.cif} className="flex w-full bg-white rounded-lg border border-gray-100 overflow-hidden">
            <div className="w-1 bg-green-500"></div>
            <div className="px-4 py-2 w-full">
              <div className="flex items-center justify-between mb-1">
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-600">{company.cif}</span>
                  <span className={`text-lg font-bold ${companyUserId === company.companyUserId ? 'text-primary' : ''}`}>
                    {company.companyName}
                  </span>
                </div>
                <span className="text-xs text-green-500 font-medium px-2 py-1 bg-green-50 rounded-full">
                  <Time d={company.createdAt} format="DD/MM/YYYY" />
                </span>
              </div>
              <div className="flex flex-col gap-1 text-xs text-gray-500">
                <div className="flex items-center">
                  <FiMapPin className="w-4 h-4 mr-1" />
                  {company.city}, {company.county}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between py-2 px-3">
              <Button variant="ghost" size="icon" className="hover:bg-muted/70">
                <FiMoreVertical className="h-4 w-4" />
              </Button>
              <Button
                onClick={async () => {
                  Actions.selectCompany(company.companyUserId)
                }}
                variant="ghost"
                size="icon"
                disabled={companyUserId === company.companyUserId}
                className="hover:bg-primary/10 hover:text-primary"
              >
                <FiEye className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
