import { Link, useLocation } from 'react-router-dom'
import { cn } from 'Utils'
import { ADMIN_MOBILE_ITEMS, APP_MOBILE_ITEMS, DEV_MOBILE_ITEMS } from 'Constants'
import { useApp } from 'Hooks'

export const AppFooter = () => {
  const { product } = useApp()
  const { pathname } = useLocation()
  let items = []
  if (product === 'webapp') items = APP_MOBILE_ITEMS
  if (product === 'devplatform') items = DEV_MOBILE_ITEMS
  if (product === 'admintool') items = ADMIN_MOBILE_ITEMS
  return (
    <footer className="flex min-h-16 w-full items-center gap-4 border-t bg-background px-6 py-3 md:hidden">
      {items
        .filter(({ hidden }) => !hidden)
        .map(({ icon: Icon, to }, i) => {
          const isActive = pathname.startsWith(to)
          return (
            <Link
              key={i}
              className={cn('flex w-full items-center justify-center max-sm:flex-col sm:gap-2', {
                'rounded-xl border-primary text-primary': isActive
              })}
              to={to}
            >
              <Icon className="size-6" />
              {/* <span className='truncate text-sm'>{title}</span> */}
            </Link>
          )
        })}
    </footer>
  )
}
