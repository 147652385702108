import { useLocalStorageSlice } from 'Hooks'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Actions } from 'Store'

export const AuthProvider = ({ children }) => {
  useLocalStorageSlice('auth', ['account', 'accessToken', 'refreshToken', 'accessTokenExpiresTs', 'refreshTokenExpiresTs'])
  return children
}

export const Logout = () => {
  useEffect(() => {
    Actions.logout()
  }, [])
  return <Navigate to="/login" />
}
