import {
  DevHome,
  LoginPanel,
  TailwindIndicator,
  RegisterAppPanel,
  AppLayout,
  NotAuthLayout,
  DevSettingsLayout,
  DevSettings,
  DevProfileLayout,
  UserProfile,
  DevApps,
  MainModal,
  AppDevelopers,
  Logout,
  NotificationsSettings,
  Notifications,
  AppSessions,
  AcceptAppPanel,
  WhoAmI,
  UserActivityLogs,
  EntityActivityLogs
} from 'Components'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

export const DevRouter = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/whoami" element={<WhoAmI />} />
        </Route>
        <Route path="logout" element={<Logout />} />
        <Route element={<NotAuthLayout />}>
          <Route path="login" element={<LoginPanel hasLinkedinLogin />} />
          <Route path="register" element={<LoginPanel hasLinkedinLogin />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route index element={<DevHome />} />
          <Route path="settings" element={<DevSettingsLayout />}>
            <Route index element={<DevSettings />} />
            <Route path="team" element={<AppDevelopers />} />
            <Route path="notifications" element={<NotificationsSettings />} />
            <Route path="applogs" element={<EntityActivityLogs />} />
          </Route>
          <Route path="notifications" element={<Notifications />} />
          <Route path="profile" element={<DevProfileLayout />}>
            <Route index element={<UserProfile />} />
            <Route path="sessions" element={<AppSessions />} />
            <Route path="apps" element={<DevApps />} />
            <Route path="logs" element={<UserActivityLogs />} />
          </Route>
          <Route path="modals" element={<MainModal />}>
            <Route path="app" element={<RegisterAppPanel />} />
            <Route path="invite" element={<AcceptAppPanel />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
    <TailwindIndicator />
  </>
)
