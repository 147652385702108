import {
  FiHome,
  FiInbox,
  FiSend,
  FiPackage,
  FiShoppingCart,
  FiFileText,
  FiArchive,
  FiUser,
  FiShoppingBag,
  FiDollarSign,
  FiCode,
  FiActivity,
  FiClock,
  FiUsers
} from 'react-icons/fi'

export const APP_SIDEBAR_ITEMS = [
  {
    id: 'dashboard',
    title: '',
    hidden: false,
    items: [{ id: 'dashboard', title: 'Dashboard', to: '/', icon: FiHome }]
  },
  {
    id: 'documents',
    title: 'Documente',
    hidden: false,
    items: [
      { id: 'rcv', title: 'Facturi primite', to: '/rcv', icon: FiInbox },
      { id: 'snt', title: 'Facturi emise', to: '/snt', icon: FiSend },
      { id: 'nir', title: 'NIR-uri', to: '/nir', icon: FiPackage },
      { id: 'bc', title: 'BC-uri', to: '/bc', icon: FiShoppingCart }
    ]
  },
  {
    id: 'resources',
    title: 'Entități',
    hidden: false,
    items: [
      { id: 'art', title: 'Articole/Servicii', to: '/art', icon: FiArchive },
      { id: 'cust', title: 'Clienti', to: '/cust', icon: FiUser },
      { id: 'furn', title: 'Furnizori', to: '/furn', icon: FiShoppingBag },
      { id: 'um', title: 'UM', to: '/um', icon: FiDollarSign }
    ]
  }
]

export const DEV_SIDEBAR_ITEMS = [
  {
    id: 'dashboard',
    title: '',
    hidden: false,
    items: [{ id: 'dashboard', title: 'Dashboard', to: '/dashboard', icon: FiHome }]
  },
  {
    id: 'apps',
    title: 'Applicatii',
    hidden: true,
    items: [{ id: 'apps', title: 'Lista aplicatii', to: '/apps', icon: FiInbox }]
  }
]

export const ADMIN_SIDEBAR_ITEMS = [
  {
    id: 'dashboard',
    title: 'Panou de bord',
    hidden: false,
    items: [
      { id: 'dashboard', title: 'Dashboard', to: '/', icon: FiHome },
      { id: 'status', title: 'Status', to: '/status', icon: FiActivity },
      { id: 'sessions', title: 'Sesiuni', to: '/sessions', icon: FiClock },
      { id: 'users', title: 'Utilizatori', to: '/users', icon: FiUsers }
    ]
  },
  {
    id: 'managers',
    title: 'Manageri',
    hidden: false,
    items: [{ id: 'admusr', title: 'Lista useri', to: '/admin-users', icon: FiInbox }]
  },
  {
    id: 'apps',
    title: 'Aplicatii',
    hidden: false,
    items: [{ id: 'apps', title: 'Lista aplicatii', to: '/apps', icon: FiCode }]
  }
]

export const APP_MOBILE_ITEMS = [
  { id: 'dashboard', hidden: true, title: 'Dashboard', to: '/dashboard', icon: FiHome },
  { id: 'rcv', title: 'Facturi primite', to: '/rcv', icon: FiInbox },
  { id: 'snt', title: 'Facturi emise', to: '/snt', icon: FiSend },
  { id: 'nir', title: 'NIR-uri', to: '/nir', icon: FiPackage },
  { id: 'bc', title: 'BC-uri', to: '/bc', icon: FiShoppingCart },
  { id: 'docs', hidden: true, title: 'NIR/BC', to: '/documents', icon: FiFileText },
  { id: 'resources', hidden: true, title: 'Resources', to: '/resources', icon: FiArchive }
]

export const DEV_MOBILE_ITEMS = [
  { id: 'dashboard', hidden: true, title: 'Dashboard', to: '/dashboard', icon: FiHome },
  { id: 'apps', title: 'Applicatii', to: '/apps', icon: FiInbox }
]

export const ADMIN_MOBILE_ITEMS = [
  { id: 'dashboard', hidden: true, title: 'Dashboard', to: '/dashboard', icon: FiHome },
  { id: 'managers', title: 'Manageri', to: '/admin-users', icon: FiInbox }
]
