import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'Components'
import { SUPPORTED_LANGUAGES, SUPPORTED_TIMEZONES } from 'Constants'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'

export const LanguageSelector = () => {
  const language = useSelector((state) => state.locales.language)

  const handleLanguageChange = (code) => {
    const locale = SUPPORTED_LANGUAGES.find((lang) => lang.code === code)?.locale
    Actions.setLocale(locale)
  }

  return (
    <Select onValueChange={handleLanguageChange} defaultValue={language}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select language" />
      </SelectTrigger>
      <SelectContent>
        {SUPPORTED_LANGUAGES.map((lang) => (
          <SelectItem key={lang.code} value={lang.code}>
            {lang.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
export const TimezoneSelector = () => {
  const timezone = useSelector((state) => state.locales.timezone)
  const handleTimezoneChange = (timezone) => {
    Actions.setTimezone(timezone)
  }
  return (
    <Select onValueChange={handleTimezoneChange} defaultValue={timezone}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select timezone" />
      </SelectTrigger>
      <SelectContent>
        {SUPPORTED_TIMEZONES.map((timezone) => (
          <SelectItem key={timezone} value={timezone}>
            {timezone}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
