import { useLocation, Outlet, useNavigate } from 'react-router-dom'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from 'Components'
import { Actions } from 'Store'
import { getQueryParamsKey } from 'Utils'

export const MainModal = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [, , part2] = location.pathname.split('/')

  const onCloseModal = () => {
    if (part2 && Actions[`${part2}Close`]) Actions[`${part2}Close`]()
    const route = getQueryParamsKey('back')
    navigate(`/${route || ''}`)
  }

  return (
    <Dialog open onOpenChange={(open) => !open && onCloseModal()}>
      <DialogTitle></DialogTitle>
      <DialogDescription></DialogDescription>
      <DialogContent className="w-screen h-screen max-w-none p-0 border-none">
        <Outlet />
      </DialogContent>
    </Dialog>
  )
}
