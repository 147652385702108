import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import {
  AppInvitationCard,
  ButtonWaiting,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

export const validationSchema = z.object({
  appName: z
    .string()
    .min(3, 'Numele trebuie să conține minim 3 caractere')
    .max(45, 'Numele trebuie să conține maxim 45 caractere'),
  serverError: z.string().optional()
})

export const AppNotApprovedScreen = () => {
  const app = useSelector((state) => state.auth.account.app)
  const email = useSelector((state) => state.auth.account.user?.email)
  const { appId, appName } = app || {}

  return (
    <div className="flex items-center justify-center p-4">
      <div className="max-w-lg rounded-lg bg-white p-8 shadow-lg">
        <div className="mb-6 text-center">
          <h2 className="mb-2 text-2xl font-semibold text-gray-800">Aplicație în așteptare</h2>
          <div className="h-1 w-16 bg-blue-500 mx-auto rounded"></div>
        </div>

        <div className="space-y-4 text-center">
          <p className="text-lg text-gray-600">
            Am trimis o cerere de aprobare pentru aplicația{' '}
            <span className="font-medium text-blue-600">
              #{appId} {appName}
            </span>
          </p>

          <p className="text-gray-600">
            Când aplicația va fi aprobată de administratorii de sistem, veți primi un email la{' '}
            <span className="font-medium text-blue-600">{email}</span> pentru a continua procesul.
          </p>
        </div>
      </div>
    </div>
  )
}

// the executeRecaptcha is passed from the parent component
export const AcceptAppForm = ({ onCloseForm }) => {
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)
  const invitation = useSelector((state) => state.auth.account.invitation)
  const form = useForm()

  const onSubmit = useCallback(async () => {
    await Actions.acceptInvitation()
    if (!errorAuth) onCloseForm?.()
  }, [errorAuth, onCloseForm])

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <AppInvitationCard invitation={invitation} />
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full" noValidate>
        {errorAuth && <p className="text-sm text-red-500 mt-3">{errorAuth?.message}</p>}
        <div className="flex items-center justify-between mt-4">
          <ButtonWaiting type="submit" isWaiting={isLoadingAuth} className="flex-1 mr-2">
            Acceptați invitația
          </ButtonWaiting>
        </div>
      </form>
    </Form>
  )
}

// the executeRecaptcha is passed from the parent component
export const RegisterAppForm = ({ onCloseForm }) => {
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)

  const defaultValues = useMemo(
    () => ({
      appName: '',
      serverError: errorAuth?.message
    }),
    [errorAuth]
  )

  const form = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues
  })

  const { setFocus } = form

  const onSubmit = useCallback(
    async ({ appName }) => {
      if (appName) {
        await Actions.registerApp({ appName })
        if (!errorAuth) onCloseForm?.()
      }
    },
    [errorAuth, onCloseForm]
  )

  useEffect(() => {
    setFocus('appName')
  }, [setFocus])

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Cateva detalii despre aplicație</h1>
      </div>

      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full" noValidate>
        <FormField
          control={form.control}
          name="appName"
          render={({ field }) => (
            <FormItem className="mb-2 block w-full text-sm text-gray-700">
              <FormLabel>Numele aplicației</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  className="focus:shadow-outline mb-3 w-full rounded-xl border border-gray-300 px-3 py-2 leading-tight focus:outline-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {errorAuth && <p className="text-sm text-red-500 mt-3">{errorAuth?.message}</p>}
        <div className="flex items-center justify-between mt-4">
          <ButtonWaiting type="submit" isWaiting={isLoadingAuth} className="flex-1 mr-2">
            Creaza aplicația
          </ButtonWaiting>
        </div>
      </form>
    </Form>
  )
}
