import { useEffect } from 'react'
import { RegisterCompanyForm, AcceptCompanyForm } from '../auth/RegisterCompanyForm'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const RegisterCompanyPanel = () => {
  const navigate = useNavigate()
  const hasCif = false
  // if (!hasUserName && !isAuth) return <ContinueUserPanel />
  return (
    <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
      {!hasCif && <RegisterCompanyForm onCloseForm={() => navigate('/')} />}
      {hasCif && <RomanianAuthForm />}
    </div>
  )
}

export const AcceptCompanyPanel = () => {
  const navigate = useNavigate()
  const invitation = useSelector((state) => state.auth.account.invitation)
  useEffect(() => {
    if (!invitation) navigate('/')
  }, [invitation, navigate])

  return (
    <div className="mx-auto flex w-full flex-col justify-center gap-4 px-0 sm:px-16">
      <AcceptCompanyForm
        onCloseForm={async () => {
          navigate('/')
        }}
      />
    </div>
  )
}

const RomanianAuthForm = () => {
  return <div>RomanianAuthForm</div>
}
