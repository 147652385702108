import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Avatar,
  AvatarImage,
  AvatarFallback
} from 'Components'
import { FiUser, FiSettings, FiLogOut, FiHelpCircle } from 'react-icons/fi'
import { getInitialsByName } from 'Utils'
import { Actions } from 'Store'
import { useApp } from 'Hooks'

export const UserMenu = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.account.user)
  const { product } = useApp()
  let settingsText = ''
  if (product === 'webapp') {
    settingsText = 'Configurare cont'
  }
  if (product === 'devplatform') {
    settingsText = 'Configurare aplicație'
  }
  if (product === 'admintool') {
    settingsText = 'Configurare generală'
  }

  const itemClasses = 'hover:bg-hover rounded-lg flex gap-4 items-center'
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="focus:outline-none">
        <Avatar>
          <AvatarImage src={user?.avatarUrl} alt={user?.userName} />
          <AvatarFallback>{getInitialsByName(user?.userName)}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-64 rounded-xl">
        <DropdownMenuItem onClick={() => navigate('/profile')} className={itemClasses}>
          <FiUser size={20} />
          <div>
            <h3 className="font-medium">{user?.userName}</h3>
            {user?.email && <p className="text-secondary text-sm">{user?.email}</p>}
          </div>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate('/settings')} className={itemClasses}>
          <FiSettings size={20} />
          {settingsText}
        </DropdownMenuItem>

        <DropdownMenuItem onClick={() => window.open('https://factcurier.ro/help', '_blank')} className={itemClasses}>
          <FiHelpCircle size={20} />
          Ajutor
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => Actions.logout()} className={`${itemClasses} text-destructive`}>
          <FiLogOut size={20} />
          Ieșire
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default UserMenu
