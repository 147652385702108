import {
  _callAPI,
  buildMeQuery,
  loginWithEmailCodeQuery,
  authenticateWithTokenQuery,
  logoutQuery,
  saveUserProfileQuery,
  sendEmailAuthCodeQuery,
  acceptInvitationQuery,
  denyInvitationQuery,
  notificationFields,
  getAccountFields,
  refreshTokensQuery,
  getUserSessionsQuery,
  getUserActivityLogsQuery,
  getEntityActivityLogsQuery
} from 'API'
import { Actions, State } from 'Store'
import { getProduct } from 'Utils'

// will only return the new account or null
export const _getMe = async ({ onlyNotifications } = {}) => {
  let fields = getAccountFields()
  const product = getProduct()
  const isAppProduct = product === 'WEBAPP'
  const isDevProduct = product === 'DEVPLATFORM'
  const isAdminProduct = product === 'ADMINTOOL'

  if (onlyNotifications)
    fields = `{
      jwtId
      userNotifications ${notificationFields}
      ${isAdminProduct ? `adminNotifications ${notificationFields}` : ''}
      ${isAppProduct ? `appNotifications ${notificationFields}` : ''}
      ${isDevProduct ? `companyNotifications ${notificationFields}` : ''}
  }`

  const { getMe: result } = (await _callAPI({ query: buildMeQuery(fields) })) || {}
  return result?.jwtId ? result : null
}

// will send the log out signal, no need to return anything
export const _logout = async ({ allOtherSessions, jwtId } = {}) => {
  // if there is no access token, it means that the user is not logged in, so we don't need to call the API
  const isAuth = !!State().auth.accessToken
  if (!isAuth) return
  await _callAPI({ query: logoutQuery, variables: { allOtherSessions: !!allOtherSessions, jwtId } })
}

export const _getUserSessions = async () => {
  const { error, getUserSessions: result } = (await _callAPI({ query: getUserSessionsQuery })) || {}
  return error ? { error } : result || []
}

// with take the one time token, and return the response tokens or the error
export const _authenticateWithToken = async (token) => {
  if (!token) return
  const { error, authenticateWithToken: result } =
    (await _callAPI({
      query: authenticateWithTokenQuery,
      variables: { token },
      allowPublic: true
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

// will request the send _email auth code and returns true if the code was sent, otherwise the error
export const _sendEmailAuthCode = async ({ email, captchaToken } = {}) => {
  if (!email) return false
  const { error, sendEmailAuthCode: isSent } =
    (await _callAPI({
      query: sendEmailAuthCodeQuery,
      variables: { email },
      allowPublic: true,
      captchaToken
    })) || {}
  return error ? { error } : isSent
}

// will send the login with _email code mutation and returns the response tokens or the error
export const _loginWithEmailCode = async ({ email, pin, captchaToken }) => {
  const { error, loginWithEmailCode: result } =
    (await _callAPI({
      query: loginWithEmailCodeQuery,
      variables: { email, pin },
      captchaToken,
      allowPublic: true
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

export const _saveUserProfile = async (userData) => {
  const { error, saveUserProfile: result } =
    (await _callAPI({
      query: saveUserProfileQuery,
      variables: { userData },
      allowPublic: true
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

export const _acceptInvitation = async () => {
  const { error, acceptInvitation: result } =
    (await _callAPI({
      query: acceptInvitationQuery
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

export const _denyInvitation = async () => {
  const { error, denyInvitation: result } =
    (await _callAPI({
      query: denyInvitationQuery
    })) || {}
  return error ? { error } : result?.account?.jwtId ? result : null
}

// get the access token from the store
export const _getAccessToken = async () => {
  const accessToken = State().auth.accessToken
  const accessTokenExpiresTs = State().auth.accessTokenExpiresTs
  const refreshToken = State().auth.refreshToken
  const refreshTokenExpiresTs = State().auth.refreshTokenExpiresTs

  if (!accessToken) return
  // if the accessToken is still valid, use it
  if (accessTokenExpiresTs && new Date() < new Date(accessTokenExpiresTs)) {
    return accessToken
  }

  // if accessToken and refresh token is still valid, use the refresh token to get a new access token
  if (refreshToken && refreshTokenExpiresTs && new Date() < new Date(refreshTokenExpiresTs)) {
    const authTokensResult = await _callAPI({
      query: refreshTokensQuery,
      isPublic: true,
      refreshToken
    })
    const { accessToken: newAccessToken, accessTokenExpiresTs: newAccessTokenExpiresTs } = authTokensResult?.refreshTokens || {}
    // if the response has an access token, authenticate new tokens
    if (newAccessToken && newAccessTokenExpiresTs) {
      Actions.refreshAuth({
        accessToken: newAccessToken,
        accessTokenExpiresTs: newAccessTokenExpiresTs
      })
      return newAccessToken
    }
  }
  // any other case, log out
  Actions.logout(true)
}

export const _getUserActivityLogs = async () => {
  const { error, getUserActivityLogs: result } = (await _callAPI({ query: getUserActivityLogsQuery })) || {}
  return error ? { error } : result || []
}

export const _getEntityActivityLogs = async () => {
  const { error, getEntityActivityLogs: result } = (await _callAPI({ query: getEntityActivityLogsQuery })) || {}
  return error ? { error } : result || []
}
