export const AppTeam = () => {
  return <div>AppTeam</div>
}

export const AppSpv = () => {
  return <div>AppSpv</div>
}

export const AppCompanyLogs = () => {
  return <div>AppCompanyLogs</div>
}
