import { ReducerActions } from 'Store'

export const setIsLoadingApp = (isLoadingApp) => {
  ReducerActions._setIsLoadingApp(isLoadingApp)
}

export const setTheme = (theme) => {
  if (!['light', 'dark', 'system'].includes(theme)) {
    theme = 'system'
  }
  ReducerActions._setTheme(theme)
}

export const showToast = ({ message, variant, duration }) => {
  ReducerActions._showToast({ message, variant, duration })
}

export const hideToast = () => {
  ReducerActions._hideToast()
}

export const onPusherMessageArrived = ({ name, data }) => {
  console.info('onPusherMessageArrived', name, data)
}
