import {
  AppHome,
  AppLayout,
  LoginPanel,
  RegisterCompanyPanel,
  NotAuthLayout,
  TailwindIndicator,
  SentInvoices,
  ReceivedInvoices,
  AppSettingsLayout,
  AppSettings,
  AppProfileLayout,
  AppSpv,
  NirInvoices,
  BcInvoices,
  AppCompanies,
  MainModal,
  UserProfile,
  CompanyEmployees,
  Logout,
  AppSessions,
  AcceptCompanyPanel,
  WhoAmI,
  UserActivityLogs,
  EntityActivityLogs
} from 'Components'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

export const AppRouter = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/whoami" element={<WhoAmI />} />
        </Route>
        <Route path="logout" element={<Logout />} />
        <Route element={<NotAuthLayout />}>
          <Route path="login" element={<LoginPanel hasGoogleLogin hasAppleLogin />} />
          <Route path="register" element={<LoginPanel hasGoogleLogin hasAppleLogin />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route index element={<AppHome />} />
          <Route path="rcv" element={<ReceivedInvoices />} />
          <Route path="snt" element={<SentInvoices />} />
          <Route path="nir" element={<NirInvoices />} />
          <Route path="bc" element={<BcInvoices />} />
          <Route path="settings" element={<AppSettingsLayout />}>
            <Route index element={<AppSettings />} />
            <Route path="spv" element={<AppSpv />} />
            <Route path="team" element={<CompanyEmployees />} />
            <Route path="companylogs" element={<EntityActivityLogs />} />
          </Route>
          <Route path="profile" element={<AppProfileLayout />}>
            <Route index element={<UserProfile />} />
            <Route path="sessions" element={<AppSessions />} />
            <Route path="companies" element={<AppCompanies />} />
            <Route path="logs" element={<UserActivityLogs />} />
          </Route>
          <Route path="modals" element={<MainModal />}>
            <Route path="company" element={<RegisterCompanyPanel />} />
            <Route path="invite" element={<AcceptCompanyPanel />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
    <TailwindIndicator />
  </>
)
