import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'
import { ReloadIcon } from '@radix-ui/react-icons'
import { cn } from 'Utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-xl text-sm font-medium transition-all disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground font-bold shadow hover:bg-primary/80',
        destructive:
          'border text-destructive shadow-sm hover:bg-destructive/5 hover:border-destructive focus-visible:ring-destructive',
        'destructive-primary':
          'border border-destructive bg-destructive text-background shadow-sm hover:bg-destructive/5 hover:text-destructive hover:border-destructive focus-visible:ring-destructive',
        outline: 'border border-input shadow-sm hover:bg-hover',
        'outline-primary': 'border text-primary hover:border-primary shadow-sm hover:bg-primary/5',
        secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:bg-hover',
        link: 'text-primary p-0 underline-offset-4 hover:underline',
        accept: 'border hover:border-accepted shadow-sm text-accepted hover:bg-accepted/10 focus-visible:ring-accepted'
      },
      size: {
        default: 'max-h-9 min-h-9 px-4 py-2',
        sm: 'min-h-8 rounded-md px-3 text-xs',
        lg: 'min-h-10 rounded-md px-8',
        icon: 'min-h-9 w-9'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button'
  return <Comp className={cn(buttonVariants({ variant, size, className }), 'text-balance')} ref={ref} {...props} />
})
Button.displayName = 'Button'

const ButtonWaiting = ({ isWaiting, disabled, type, ...props }) => {
  let iconComp
  if (isWaiting) {
    disabled = true
    iconComp = <ReloadIcon className="mr-2 size-4 animate-spin" />
  }
  return (
    <Button disabled={disabled} type={type || 'submit'} {...props}>
      {iconComp}
      {props.children}
    </Button>
  )
}
ButtonWaiting.displayName = 'Button'

export { ButtonWaiting, Button, buttonVariants }
