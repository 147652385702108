import { Button, T } from 'Components'

export const AppHome = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">
          <T k="home.title" />
        </h2>
        <p className="text-gray-600 mb-8">
          <T k="home.description" />
        </p>
        <Button
          onClick={() => {}}
          className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
        >
          <T k="home.notImplemented" />
        </Button>
      </div>
    </div>
  )
}
