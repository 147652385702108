import { Outlet } from 'react-router-dom'
import { AppFooter, AppHeader, AppSidebar } from 'Components'
import { useAuthenticatedRedirects } from 'Hooks'

export const AppLayout = () => {
  useAuthenticatedRedirects()
  return (
    <div className="flex h-svh max-h-svh flex-col overflow-hidden">
      <div className="flex size-full overflow-hidden">
        <AppSidebar />
        <div className="w-full">
          <AppHeader />
          <Outlet />
        </div>
      </div>
      <AppFooter />
    </div>
  )
}
