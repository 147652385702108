import { Tabs, TabsList, TabsTrigger, ProfileHeader } from 'Components'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

export const DevProfileLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const activeTab = location.pathname === '/profile' ? 'profile' : location.pathname.split('/').pop()
  const user = useSelector((state) => state.auth.account.user)

  const handleTabChange = (value) => {
    navigate(value === 'profile' ? '/profile' : `/profile/${value}`)
  }

  return (
    <div className="container mx-auto max-w-3xl p-6">
      <ProfileHeader user={user} />
      <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
        <TabsList className="mb-8">
          <TabsTrigger value="profile">Profilul meu</TabsTrigger>
          <TabsTrigger value="sessions">Sesiuni active</TabsTrigger>
          <TabsTrigger value="apps">Aplicațiile mele</TabsTrigger>
          <TabsTrigger value="logs">Activitate</TabsTrigger>
        </TabsList>
        <div className="overflow-y-auto max-h-[calc(100vh-300px)]">
          <Outlet />
        </div>
      </Tabs>
    </div>
  )
}
