import { useSelector } from 'react-redux'
import { memoize } from 'proxy-memoize'

export const useListItem = (list, id) => {
  return useSelector((state) => state.lists?.[list]?.[id])
}

export const useList = (list, sortKey, isDesc) => {
  sortKey = sortKey || 'orderIndex'
  const listObj = useSelector(memoize((state) => (list ? Object.entries(state.lists?.[list]) : [])))
  return listObj
    .sort(([, valuea], [, valueb]) => ((isDesc ? valuea[sortKey] > valueb[sortKey] : valuea[sortKey] < valueb[sortKey]) ? -1 : 1))
    .map(([key]) => key)
}

export const useListCount = (list) => {
  return useSelector((state) => Object.keys(state.lists?.[list] || {}).length)
}
