import React, { useEffect } from 'react'
import { FiClock, FiGlobe, FiMonitor, FiChrome, FiLogOut } from 'react-icons/fi'
import { Card, CardHeader, CardTitle, CardContent, Button, ListContainer, Time } from 'Components'
import { useSelector } from 'react-redux'
import API from 'API'
import { Actions } from 'Store'
import { useListCount, useListItem } from 'Hooks'

const SessionItem = ({ id }) => {
  const session = useListItem('userSessions', id)
  const isCurrentSession = useSelector((state) => state.auth.account.jwtId === id)
  return (
    <div
      key={id}
      className={`mb-4 p-4 rounded-lg border ${
        isCurrentSession
          ? 'bg-primary/10 border-primary/20 dark:bg-primary/20 dark:border-primary/30'
          : 'bg-background border-border'
      }`}
    >
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <div className="flex items-center gap-2 mb-2">
            <FiMonitor className="h-4 w-4 text-muted-foreground" />
            <span className="text-[10px] text-muted-foreground">#{id}</span>
            <span className="font-medium text-foreground">{session.deviceType}</span>
            {isCurrentSession && (
              <span className="px-2 py-1 text-xs font-medium text-primary bg-primary/10 dark:bg-primary/20 rounded-full">
                Sesiune curentă
              </span>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm text-gray-600">
            <div className="flex items-center gap-2">
              <FiChrome className="h-4 w-4" />
              <span>
                {session.browser} on {session.os}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FiGlobe className="h-4 w-4" />
              <span>{session.location}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-medium">IP:</span>
              <span>{session.ipAddress}</span>
            </div>
            <div className="flex items-center gap-2">
              <FiClock className="h-4 w-4" />
              <span>
                Expiră: <Time format="DD/MM/YYYY HH:mm" d={session.expiresTs} />
              </span>
            </div>
          </div>

          <div className="mt-2 text-xs text-gray-500">
            <div>
              Logat la: <Time format="DD/MM/YYYY HH:mm" d={session.createdTs} />
            </div>
            <div>
              Ultima activitate: <Time format="DD/MM/YYYY HH:mm" d={session.refreshedTs} />
            </div>
          </div>
        </div>

        {!isCurrentSession && (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => Actions.logout(null, { jwtId: id })}
            className="text-red-600 hover:text-red-700"
          >
            <FiLogOut className="h-4 w-4 mr-2" />
            Întrerupe sesiunea
          </Button>
        )}
      </div>
    </div>
  )
}

export const AppSessions = () => {
  const listCount = useListCount('userSessions')

  useEffect(() => {
    const syncList = async () => {
      const userSessions = await API._getUserSessions()
      if (userSessions?.error) return
      Actions.populateUserSessions(userSessions)
    }
    syncList()
  }, [])

  return (
    <Card className="w-full max-w-4xl">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Sesiuni active</CardTitle>
        {listCount > 1 && (
          <Button
            variant="outline"
            onClick={() => Actions.logout(null, { allOtherSessions: true })}
            className="text-red-600 hover:text-red-700"
          >
            Întrerupe celelalte sesiuni
          </Button>
        )}
      </CardHeader>
      <CardContent>
        <ListContainer list="userSessions" id="jwtId" sortKey="refreshedTs" isDesc>
          <SessionItem />
        </ListContainer>
      </CardContent>
    </Card>
  )
}
