import { createSlice } from '@reduxjs/toolkit'
import { getLocalStorageKey } from 'Utils'

const initialState = {
  isLoadingAuth: true,
  account: {},
  accessToken: null,
  accessTokenExpiresTs: null,
  refreshToken: null,
  refreshTokenExpiresTs: null,
  errorAuth: null
}
// the old auth state
const authStorage = getLocalStorageKey('auth')

const slice = createSlice({
  name: 'auth',
  initialState: { ...initialState, ...authStorage },
  reducers: {
    _setLoadingAuth: (state, action) => {
      state.isLoadingAuth = !!action.payload
      state.errorAuth = null
    },
    _setErrorAuth: (state, action) => {
      state.isLoadingAuth = false
      state.errorAuth = action.payload || null
    },
    _mergeAccount: (state, action) => {
      state.account = { ...state.account, ...action.payload }
    },
    _setInvitationSeen: (state) => {
      if (state.account.invitation) state.account.invitation.isSeen = true
    },
    _setAccount: (state, action) => {
      state.account = action.payload || state.account
    },
    _refreshAuth: (state, action) => {
      const { accessToken, accessTokenExpiresTs } = action.payload || {}
      if (
        state.account &&
        accessToken &&
        state.refreshToken &&
        accessTokenExpiresTs &&
        state.refreshTokenExpiresTs &&
        new Date(accessTokenExpiresTs) > new Date() &&
        new Date(state.refreshTokenExpiresTs) > new Date() &&
        new Date(state.refreshTokenExpiresTs) > new Date(accessTokenExpiresTs)
      ) {
        state.accessToken = accessToken || state.accessToken
        state.accessTokenExpiresTs = accessTokenExpiresTs || state.accessTokenExpiresTs
      }
    },
    _setAuth: (state, action) => {
      const { account, accessToken, refreshToken, accessTokenExpiresTs, refreshTokenExpiresTs } = action.payload || {}
      if (
        account &&
        accessToken &&
        refreshToken &&
        accessTokenExpiresTs &&
        refreshTokenExpiresTs &&
        new Date(accessTokenExpiresTs) > new Date() &&
        new Date(refreshTokenExpiresTs) > new Date() &&
        new Date(refreshTokenExpiresTs) > new Date(accessTokenExpiresTs)
      ) {
        state.account = account
        state.accessToken = accessToken
        state.refreshToken = refreshToken
        state.accessTokenExpiresTs = accessTokenExpiresTs
        state.refreshTokenExpiresTs = refreshTokenExpiresTs
      } else Object.assign(state, initialState)
      state.isLoadingAuth = false
      state.errorAuth = null
    }
  }
})

export const reducerActions = slice.actions

export default slice.reducer
