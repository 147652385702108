import { GetSelectorComponentWithDivWrapper, MenuSidebar } from 'Components'
import { useApp } from 'Hooks'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Actions } from 'Store'
import { cn } from 'Utils'

export const AppSidebar = ({ MenuComponent = MenuSidebar }) => {
  const { isSidebarOpen } = useApp()
  const { pathname } = useLocation()

  const SelectorComponent = GetSelectorComponentWithDivWrapper('grid gap-y-4 px-6 py-4')

  // change in the route will close the sidebar
  useEffect(() => {
    Actions.toggleSidebar(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  return (
    <>
      <div
        className={cn('z-20 flex h-full min-w-[250px] max-w-[250px] max-xl:absolute max-md:h-[calc(100%-64px)]', {
          'max-xl:hidden': !isSidebarOpen
        })}
      >
        <aside className="flex size-full flex-col border-r bg-background">
          {SelectorComponent}

          {MenuComponent && (
            <div className="small-scrollbar max-h-50 mr-px flex h-full flex-col gap-4 overflow-y-auto px-6 py-4">
              <MenuComponent />
            </div>
          )}
        </aside>
      </div>
      {/* blur container */}
      {isSidebarOpen && (
        <div
          className="absolute right-0 z-10 h-full w-screen cursor-pointer bg-black/40 max-md:h-[calc(100%-64px)] xl:hidden"
          onClick={() => Actions.toggleSidebar()}
        ></div>
      )}
    </>
  )
}
