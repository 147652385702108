import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton
} from 'Components'
import { useMediaQuery } from 'Hooks'
import { FiArrowDownCircle, FiPlusCircle } from 'react-icons/fi'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { cn } from 'Utils'
import { Link } from 'react-router-dom'
import { Actions } from 'Store'

export const CompaniesSelector = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const [isComboOpen, setIsComboOpen] = useState(false)

  return isDesktop ? (
    <CompaniesDesktop isComboOpen={isComboOpen} setIsComboOpen={setIsComboOpen} />
  ) : (
    <CompaniesMobile isComboOpen={isComboOpen} setIsComboOpen={setIsComboOpen} />
  )
}

const CompaniesDesktop = ({ isComboOpen, setIsComboOpen }) => {
  const isCompanyLoading = useSelector((state) => state.auth.isLoadingAuth)
  const companyName = useSelector((state) => state.auth.account?.company?.companyName)
  return (
    <Popover open={isComboOpen} onOpenChange={setIsComboOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" className="w-full justify-between overflow-hidden" disabled={isCompanyLoading}>
          {isCompanyLoading ? <Skeleton className="h-5 w-full" /> : <span className="truncate text-sm">{companyName}</span>}
          <FiArrowDownCircle className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[200px] p-0" align="start">
        <CompaniesList setIsComboOpen={setIsComboOpen} />
      </PopoverContent>
    </Popover>
  )
}

const CompaniesMobile = ({ isComboOpen, setIsComboOpen }) => {
  const isCompanyLoading = useSelector((state) => state.auth.isLoadingAuth)
  const companyName = useSelector((state) => state.auth.account?.company?.companyName)

  return (
    <Drawer open={isComboOpen} onOpenChange={setIsComboOpen}>
      <DrawerTrigger asChild>
        <Button variant="outline" className="w-full justify-between overflow-hidden">
          {isCompanyLoading ? <Skeleton className="h-5 w-full" /> : <span className="truncate text-sm">{companyName}</span>}
          <FiArrowDownCircle className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Firme</DrawerTitle>
          <DrawerDescription>Firmele cu care lucrezi</DrawerDescription>
        </DrawerHeader>
        <div className="mt-4 border-t">
          <CompaniesList setIsComboOpen={setIsComboOpen} />
        </div>
      </DrawerContent>
    </Drawer>
  )
}

const CompaniesList = ({ setIsComboOpen }) => {
  const companyUserId = useSelector((state) => state.auth.account?.company?.companyUserId)
  const companies = useSelector((state) => state.auth.account?.userCompanies)
  const accounts = (companies || []).map((company) => ({
    companyUserId: company.companyUserId,
    cif: company.cif,
    companyName: company.companyName,
    isSelected: true
  }))

  const onSelectAccount = async (companyUserId) => {
    await Actions.selectCompany(companyUserId)
    Actions.toggleSidebar(false)
  }
  return (
    <div>
      <Command>
        <CommandInput placeholder="Filter status..." className="ring-0 ring-offset-0" />
        <CommandList>
          <CommandEmpty>Nu s-au gasit rezultate.</CommandEmpty>
          {accounts.length > 0 && (
            <CommandGroup heading="Firme">
              {accounts.map((account) => (
                <CommandItem
                  className={cn(
                    companyUserId === account.companyUserId ? 'bg-primary/5 text-primary aria-selected:bg-primary/10' : ''
                  )}
                  key={account.companyUserId}
                  value={account.companyName}
                  onSelect={() => {
                    onSelectAccount(account.companyUserId)
                    setIsComboOpen(false)
                  }}
                >
                  {account.cif} {account.companyName}
                </CommandItem>
              ))}
            </CommandGroup>
          )}
        </CommandList>
      </Command>
      <div className="w-full border-b"></div>
      <Link
        to="/modals/company"
        className="m-1 flex cursor-pointer flex-row items-center gap-2 rounded px-2 py-1.5 text-sm font-bold text-primary hover:bg-hover"
        onClick={() => {
          setIsComboOpen(false)
        }}
      >
        <FiPlusCircle className="size-4" />
        <p className="truncate">Adauga alta firma</p>
      </Link>
    </div>
  )
}
