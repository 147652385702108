import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Actions } from 'Store'
import {
  ButtonWaiting,
  CompanyInvitationCard,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from 'Components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { FiRefreshCw } from 'react-icons/fi'
import { debounce } from 'lodash'

export const validationSchema = z.object({
  companyName: z
    .string()
    .min(3, 'Numele trebuie să conțină minim 3 caractere')
    .max(45, 'Numele trebuie să conțină maxim 45 caractere'),
  cif: z.string().min(3, 'CIF trebuie să conțină minim 3 caractere').max(10, 'CIF trebuie să conțină maxim 10 caractere'),
  city: z.string().optional(),
  county: z.string().optional(),
  serverError: z.string().optional()
})

export const CompanyNotApprovedScreen = () => {
  return <div>CompanyNotApprovedScreen</div>
}

// the executeRecaptcha is passed from the parent component
export const AcceptCompanyForm = ({ onCloseForm }) => {
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)
  const invitation = useSelector((state) => state.auth.account.invitation)
  const form = useForm()

  const onSubmit = useCallback(async () => {
    await Actions.acceptInvitation()
    if (!errorAuth) onCloseForm?.()
  }, [errorAuth, onCloseForm])

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <CompanyInvitationCard invitation={invitation} />
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full" noValidate>
        {errorAuth && <p className="text-sm text-red-500 mt-3">{errorAuth?.message}</p>}
        <div className="flex items-center justify-between mt-4">
          <ButtonWaiting type="submit" isWaiting={isLoadingAuth} className="flex-1 mr-2">
            Acceptați invitația
          </ButtonWaiting>
        </div>
      </form>
    </Form>
  )
}

export const RegisterCompanyForm = ({ onCloseForm }) => {
  const [searchResults, setSearchResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [showManualInput, setShowManualInput] = useState(false)
  const searchRef = useRef(null)

  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const errorAuth = useSelector((state) => state.auth.errorAuth)

  const defaultValues = useMemo(
    () => ({
      searchTerm: '',
      companyName: '',
      cif: '',
      city: '',
      county: '',
      serverError: errorAuth?.message
    }),
    [errorAuth]
  )

  const form = useForm({ resolver: zodResolver(validationSchema), defaultValues })

  const { setValue, setFocus } = form

  useEffect(() => {
    // Set focus on the search input when the component mounts
    if (!showManualInput) {
      setFocus('searchTerm')
    } else {
      setFocus('cif')
    }
  }, [setFocus, showManualInput])

  const performSearch = useCallback(async (searchText) => {
    if (searchText.length < 5) {
      setSearchResults([])
      setIsSearching(false)
      return
    }

    setIsSearching(true)
    try {
      const companies = await Actions.searchRomanianCompanies(searchText)
      setSearchResults(companies)
    } catch (error) {
      console.error('Error searching companies:', error)
      setSearchResults([])
    } finally {
      setIsSearching(false)
    }
  }, [])

  const debouncedSearch = useMemo(() => debounce(performSearch, 1000), [performSearch])

  const handleSearch = useCallback(
    (searchText) => {
      if (searchRef.current) {
        clearTimeout(searchRef.current)
      }
      searchRef.current = setTimeout(() => {
        debouncedSearch(searchText)
      }, 1000)
    },
    [debouncedSearch]
  )

  useEffect(() => {
    return () => {
      if (searchRef.current) {
        clearTimeout(searchRef.current)
      }
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const [selectedCompany, setSelectedCompany] = useState(null)

  const handleCompanySelect = (company) => {
    setValue('cif', company.cif)
    setValue('companyName', company.name)
    setValue('city', company.city)
    setValue('county', company.county)
    setSearchResults([])
    setSelectedCompany(company)
    setShowManualInput(true)
  }

  const handleBackToSearch = () => {
    setShowManualInput(false)
    setValue('cif', '')
    setValue('companyName', '')
    setValue('city', '')
    setValue('county', '')
    setSelectedCompany(null)
    setFocus('searchTerm')
    Actions.setErrorAuth()
  }

  const onSubmit = useCallback(
    async ({ companyName, cif, city, county }) => {
      if (companyName && cif) {
        await Actions.registerCompany({ companyName, cif, city, county, country: 'RO' })
        if (!errorAuth) onCloseForm?.()
      }
    },
    [errorAuth, onCloseForm]
  )

  return (
    <Form {...form} className="flex h-svh flex-col items-center justify-center">
      <div className="flex flex-col space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Date firma pentru care se va crea contul</h1>
      </div>
      <div className="flex flex-col space-y-2">
        <p className="text-sm text-muted-foreground">
          Recomandam sa introduci date reale, altfel nu vei putea accesa toate funcțiile. Poti cauta dupa codul fiscal fara RO sau
          dupa denumirea companiei.
        </p>
      </div>

      {!showManualInput && (
        <div className="relative">
          <Input
            {...form.register('searchTerm')}
            placeholder="Introdu codul fiscal sau denumirea"
            onChange={(e) => handleSearch(e.target.value)}
            className="w-full p-3 rounded-lg"
            disabled={isSearching}
          />

          {isSearching && (
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <FiRefreshCw className="animate-spin h-5 w-5 text-gray-400" />
            </div>
          )}

          {searchResults.length > 0 && (
            <div className="absolute w-full mt-1 bg-white rounded-lg shadow-lg max-h-96 overflow-y-auto z-10">
              {searchResults.map((company) => (
                <button
                  key={company.cif}
                  className="w-full p-3 text-left hover:bg-gray-100 border-b last:border-b-0"
                  onClick={() => handleCompanySelect(company)}
                >
                  <div className="font-medium">{company.name}</div>
                  <div className="text-sm text-gray-600">
                    {company.cif}, {company.city}
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      )}

      <p className="text-sm text-gray-600 mt-2">
        {showManualInput ? (
          <button className="text-blue-600 hover:underline" onClick={handleBackToSearch}>
            ← Înapoi la căutare
          </button>
        ) : (
          <button className="text-blue-600 hover:underline" onClick={() => setShowManualInput(true)}>
            Nu îți găsești firma în listă? Utilizează mai multe caractere.
          </button>
        )}
      </p>

      {showManualInput && (
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
          {!selectedCompany && (
            <>
              <FormField
                control={form.control}
                name="cif"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>CIF</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="companyName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Numele companiei</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Oraș</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="county"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Județ</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          {selectedCompany && (
            <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
              <div className="flex items-center">
                <div className="w-1/5 font-semibold">{selectedCompany.cif}</div>
                <div className="w-3/5 truncate">{selectedCompany.name}</div>
                <div className="w-1/5 text-right text-sm text-gray-600">
                  {selectedCompany.city}, {selectedCompany.county}
                </div>
              </div>
            </div>
          )}

          {errorAuth && <p className="text-sm text-red-500">{errorAuth?.message}</p>}
          <div className="flex items-center justify-between mt-4">
            <ButtonWaiting type="submit" isWaiting={isLoadingAuth} className="flex-1 mr-2">
              {selectedCompany ? 'Confirmă și Continuă' : 'Continuă'}
            </ButtonWaiting>
          </div>
        </form>
      )}
    </Form>
  )
}
