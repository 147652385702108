import { AppLogo, Button, GetSelectorComponentWithDivWrapper, NotificationsMenu, UserMenu } from 'Components'
import { useApp } from 'Hooks'
import { FiMenu, FiX, FiMoon, FiSun } from 'react-icons/fi'
import { Actions } from 'Store'
import { cn } from 'Utils'

export const AppHeader = () => {
  const SelectorComponent = GetSelectorComponentWithDivWrapper('md:hidden')
  const { theme } = useApp()
  return (
    <header className="flex h-16 w-full items-center justify-between gap-8 border-b bg-background px-6">
      <div className="flex items-center gap-4 max-sm:w-1/2">
        <SidebarTrigger className="hidden md:max-xl:flex" />
        <AppLogo className="flex max-w-[120px] max-md:hidden" />
        {SelectorComponent}
      </div>

      <div className="flex items-center gap-4">
        <Button
          variant="ghost"
          onClick={() => (theme === 'dark' ? Actions.setTheme('light') : Actions.setTheme('dark'))}
          className="px-4 py-2"
        >
          {theme === 'dark' ? <FiSun className="h-5 w-5" /> : <FiMoon className="h-5 w-5" />}
        </Button>
        <NotificationsMenu />
        <UserMenu />
      </div>
    </header>
  )
}

export const SidebarTrigger = ({ className }) => {
  const { isSidebarOpen } = useApp()
  return (
    <Button variant="ghost" onClick={() => Actions.toggleSidebar()} className={cn('px-4 py-2', className)}>
      <FiMenu className={cn({ hidden: isSidebarOpen })} />
      <FiX className={cn({ hidden: !isSidebarOpen })} />
    </Button>
  )
}
