import { AppLogo, AppsSelector, CompaniesSelector } from 'Components'
import { useApp } from 'Hooks'

export const GetSelectorComponentWithDivWrapper = (className) => {
  const { product } = useApp()
  let SelectorComponent = CompaniesSelector
  if (product === 'webapp') SelectorComponent = CompaniesSelector
  if (product === 'devplatform') SelectorComponent = AppsSelector
  if (product === 'admintool') SelectorComponent = AppLogo
  return (
    <div className={className}>
      <SelectorComponent />
    </div>
  )
}
