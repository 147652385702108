import { Avatar, AvatarFallback, AvatarImage, Time } from 'Components'
import { getInitialsByName } from 'Utils'
import { useState, useRef } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { Actions } from 'Store'

export const ProfileHeader = ({ user }) => {
  const fileInputRef = useRef(null)
  const [isHovering, setIsHovering] = useState(false)

  const handleAvatarClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = async (e) => {
    const file = e.target.files?.[0]
    if (file && file.type.startsWith('image/')) {
      const contentType = file.type
      const reader = new FileReader()
      reader.onload = async (event) => {
        const base64String = event.target?.result
        Actions.saveUserProfile({ avatarFile: { base64String, contentType } })
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="flex items-center gap-4 mb-8">
      <div
        className="relative"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={handleAvatarClick}
      >
        <Avatar className="h-16 w-16 cursor-pointer">
          <AvatarImage src={user?.avatarUrl} alt={user?.userName} />
          <AvatarFallback>{getInitialsByName(user?.userName)}</AvatarFallback>
        </Avatar>
        {isHovering && (
          <div className="absolute inset-0 bg-black/30 rounded-full flex items-center justify-center">
            <FiEdit2 className="w-6 h-6 text-white" />
          </div>
        )}
        <input ref={fileInputRef} type="file" className="hidden" accept="image/*" onChange={handleFileChange} />
      </div>
      <div>
        <h1 className="text-2xl font-bold">{user?.userName}</h1>
        <p className="text-sm text-muted-foreground">
          Cont deschis pe <Time d={user?.createdAt} format="DD/MM/YYYY" />
        </p>
      </div>
    </div>
  )
}
