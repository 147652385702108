import { useSelector } from 'react-redux'
import { Button, Separator, Input, Switch } from 'Components'
import { FiEdit, FiChevronDown, FiMail, FiX, FiCheck } from 'react-icons/fi'
import { useState } from 'react'
import { Actions } from 'Store'

export const UserProfile = () => {
  const user = useSelector((state) => state.auth.account?.user)
  const isLoadingAuth = useSelector((state) => state.auth.isLoadingAuth)
  const [isEditing, setIsEditing] = useState(false)
  const [guestUserName, setGuestUserName] = useState(user?.userName)
  const [isEmailNotificationsEnabled, setIsEmailNotificationsEnabled] = useState(true)
  const handleSave = async () => {
    if (guestUserName && guestUserName.trim() !== '') {
      await Actions.saveUserProfile({ userName: guestUserName })
      setIsEditing(false)
    }
  }

  const handleCancel = () => {
    setGuestUserName(user?.userName)
    setIsEditing(false)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave()
    } else if (e.key === 'Escape') {
      handleCancel()
    }
  }

  return (
    <div className="space-y-6">
      {/* Name Section */}
      <div className="flex items-start justify-between py-4">
        {!isEditing || isLoadingAuth ? (
          <div className="space-y-1">
            <h3 className="text-lg font-semibold">Nume</h3>
            {isLoadingAuth ? (
              <div className="h-10 w-48 flex items-center">
                <div className="animate-spin h-4 w-4 border-2 border-primary/20 border-t-primary rounded-full" />
              </div>
            ) : (
              <p className="text-muted-foreground h-10 flex items-center">{user?.userName}</p>
            )}
          </div>
        ) : (
          <div className="flex-1 mr-4 space-y-1">
            <h3 className="text-lg font-semibold">Nume</h3>
            <div className="flex gap-2">
              <Input
                value={guestUserName}
                onChange={(e) => setGuestUserName(e.target.value)}
                onKeyDown={handleKeyDown}
                className="max-w-md h-10"
                autoFocus
                placeholder="Introduceți numele"
              />
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleCancel}
                  className="h-10 px-3 hover:bg-destructive/10 hover:text-destructive"
                >
                  <FiX className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleSave}
                  className="h-10 px-3 hover:bg-primary/10 hover:text-primary"
                  disabled={guestUserName?.trim() === user?.userName}
                >
                  <FiCheck className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        )}
        {!isEditing && (
          <Button
            variant="outline"
            size="sm"
            disabled={isLoadingAuth}
            className="h-8 hover:bg-primary/10 hover:text-primary transition-colors"
            onClick={() => setIsEditing(true)}
          >
            <FiEdit className="h-4 w-4 mr-2" />
            Modifică
          </Button>
        )}
      </div>
      <Separator />

      {/* Sign-in Method Section */}
      <div className="flex items-center justify-between py-4">
        <div>
          <h3 className="text-lg font-semibold">Sign-in method</h3>
          {user?.email && (
            <div className="flex items-center gap-2 mt-1">
              <FiMail className="h-4 w-4 text-muted-foreground" />
              <span className="text-muted-foreground">{user?.email}</span>
            </div>
          )}
        </div>
        <Button disabled variant="outline" size="sm" className="h-8 hover:bg-primary/10 hover:text-primary transition-colors">
          <FiChevronDown className="h-4 w-4 mr-2" />
          Change
        </Button>
      </div>
      <Separator />

      {/* Email Subscriptions Section */}
      <div className="flex items-center justify-between py-4 w-full">
        <div className="flex-1">
          <h3 className="text-lg font-semibold">Notificări prin email</h3>
          <p className="text-muted-foreground">Gestionează preferințele de notificare prin email</p>
        </div>
        <div className="flex items-center gap-2 ml-4">
          <Switch
            id="email-notifications"
            checked={isEmailNotificationsEnabled}
            className="data-[state=checked]:bg-primary data-[state=unchecked]:bg-muted"
            onCheckedChange={(checked) => {
              setIsEmailNotificationsEnabled(checked)
            }}
          />
        </div>
      </div>
      <Separator />

      {/* Deactivate Account Section */}
      <div className="flex items-start justify-between py-4">
        <div className="max-w-[75%]">
          <h3 className="text-lg font-semibold text-destructive">Dezactivează contul</h3>
          <p className="text-muted-foreground">Înainte de a dezactiva contul, trebuie să nu fiți proprietarul niciunei firme.</p>
        </div>
        <Button disabled variant="destructive" size="sm" className="hover:bg-destructive/20 transition-colors">
          Deactivate
        </Button>
      </div>
    </div>
  )
}
