import { ADMIN_SIDEBAR_ITEMS, APP_SIDEBAR_ITEMS, DEV_SIDEBAR_ITEMS } from 'Constants'
import { useApp } from 'Hooks'
import { Link, useLocation } from 'react-router-dom'
import { cn } from 'Utils'

export const MenuSidebar = () => {
  const { pathname } = useLocation()
  const route1 = pathname.split('/')[1]
  const { product } = useApp()

  let items = []
  if (product === 'webapp') items = APP_SIDEBAR_ITEMS
  if (product === 'devplatform') items = DEV_SIDEBAR_ITEMS
  if (product === 'admintool') items = ADMIN_SIDEBAR_ITEMS

  const allItems = items.map((item) => ({
    ...item,
    isActive: item.items.some((item) => item.to === '/' + route1),
    items: item.items.map((item) => ({
      ...item,
      isActive: item.to === '/' + route1
    }))
  }))
  return (
    <>
      {allItems.map(
        (category) =>
          !category.hidden && (
            <div key={category.id} className="flex flex-col gap-2">
              <span className="text-xs text-secondary">{category.title}</span>
              {category.items.map((item) => {
                const { icon: Icon, isActive, title, to } = item
                return (
                  <Link
                    key={to}
                    className={cn('flex items-center gap-4 rounded-xl px-4 py-3 border', {
                      'text-primary': isActive,
                      'hover:underline': !isActive
                    })}
                    to={to}
                  >
                    <Icon /> <span className="text-sm">{title}</span>
                  </Link>
                )
              })}
            </div>
          )
      )}
    </>
  )
}
